




import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import "../jodit.es2018.min.css";

//@ts-ignore
import {Jodit} from "../jodit.es2018.min.js";

@Component
export default class JoditEditor extends Vue {
    @Prop({type: String, required: true}) readonly value!: string;
    @Prop({type: Array, default: null}) readonly buttons!: string[] | null;
    @Prop({type: Array, default: null}) readonly extraButtons!: string[] | null;
    @Prop({type: Object, default: () => ({})}) readonly config!: any;

    editor: any = null;

    get editorConfig() {
        const config = {...this.config};

        if (this.buttons) {
            config.buttons = this.buttons;
            config.buttonsMD = this.buttons;
            config.buttonsSM = this.buttons;
            config.buttonsXS = this.buttons;
        }

        if (this.extraButtons) config.extraButtons = this.extraButtons;
        config.fileBrowser;
        return config;
    }

    @Watch("value")
    onValueChange(newValue: string) {
        if (this.editor?.value !== null) this.editor.value = newValue;
    }

    mounted() {
        //@ts-ignore
        this.editor = new Jodit(this.$el, this.editorConfig);
        this.editor.value = this.value;
        this.editor.events.on("change", (newValue: string) => this.$emit("input", newValue));
    }

    beforeDestroy () {
        this.editor?.destruct();
    }
}
